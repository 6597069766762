import * as Types from '../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TriviaContestsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TriviaContestsQuery = { __typename?: 'Query', triviaContests: Array<{ __typename?: 'TriviaContest', description?: string | null | undefined, id: number, user_completed_contest: boolean, title?: string | null | undefined, questions: Array<{ __typename?: 'Question', id: string, title?: string | null | undefined, is_correct?: boolean | null | undefined, choices: Array<{ __typename?: 'Choice', id: string, title?: string | null | undefined } | null | undefined> } | null | undefined> } | null | undefined> };

export type SubmitAnswerMutationVariables = Types.Exact<{
  choiceId: Types.Scalars['String'];
}>;


export type SubmitAnswerMutation = { __typename?: 'Mutation', submitAnswer: { __typename?: 'SubmitAnswerOutput', correct_choice_id?: string | null | undefined } };

export type GetUserContestPrizeQueryVariables = Types.Exact<{
  contestId: Types.Scalars['Int'];
}>;


export type GetUserContestPrizeQuery = { __typename?: 'Query', getUserContestPrize: { __typename?: 'TriviaContestPrizeOutput', coupon_code?: string | null | undefined, discount_percentage?: string | null | undefined, coupon_description?: string | null | undefined } };

export declare const TriviaContests: import("graphql").DocumentNode;
export declare const SubmitAnswer: import("graphql").DocumentNode;
export declare const GetUserContestPrize: import("graphql").DocumentNode;

export const TriviaContestsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TriviaContests"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"triviaContests"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user_completed_contest"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"questions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"is_correct"}},{"kind":"Field","name":{"kind":"Name","value":"choices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]}}]}}]} as unknown as DocumentNode<TriviaContestsQuery, TriviaContestsQueryVariables>;
export const SubmitAnswerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SubmitAnswer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"choiceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"submitAnswer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"choice_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"choiceId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"correct_choice_id"}}]}}]}}]} as unknown as DocumentNode<SubmitAnswerMutation, SubmitAnswerMutationVariables>;
export const GetUserContestPrizeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserContestPrize"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserContestPrize"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"contestId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contestId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"coupon_code"}},{"kind":"Field","name":{"kind":"Name","value":"discount_percentage"}},{"kind":"Field","name":{"kind":"Name","value":"coupon_description"}}]}}]}}]} as unknown as DocumentNode<GetUserContestPrizeQuery, GetUserContestPrizeQueryVariables>;