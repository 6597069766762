import { ssrRef, useFetch, useAsync, computed, ref } from '@nuxtjs/composition-api';
import { useQuery } from 'villus';
import { FeaturedBrandsProductSliderDocument, MegaMenuBrandsQuery } from './../graphql/Brand';
import { useCachedSsrRef } from './serverCache';
import { useSetLocaleToCacheParams } from './i18n';
import { useSetCorporateSourceToCacheParams } from './elite';
import { BrandDocument, BrandQuery, BrandsDocument, BrandsQuery, MegaMenuBrandsDocument } from '~/graphql/Brand';
import { pipe } from '~/utils/collections';
import { Offer } from '~/graphql-types.gen';

export function useBrands() {
  const { cacheParam } = useSetLocaleToCacheParams();

  const brands = useCachedSsrRef<BrandsQuery['brands']['items']>(cacheParam('brands'), []);

  const { execute, isFetching } = useQuery({
    query: BrandsDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  useFetch(async () => {
    // Don't refetch brands unless cache expired
    if (brands.value.length) {
      return;
    }

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    brands.value = data?.brands.items || [];
  });

  const featuredBrandSlider = computed(() => brands.value.filter(brand => brand && brand.is_shown_in_homepage));
  return {
    brands,
    isFetching,
    featuredBrandSlider,
  };
}

type MappedOffers = Record<string | number, Offer> | {} | undefined;

export function useBrand(slug: string) {
  const brand = ssrRef<BrandQuery['brand'] | null | undefined>(null, 'brand');
  const mappedOffers = ssrRef<MappedOffers>({}, 'mappedOffers');

  const { isFetching, execute } = useQuery({
    query: BrandDocument,
    variables: {
      slug,
    },
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  useAsync(async () => {
    const { data } = await execute();

    brand.value = data?.brand;

    mappedOffers.value = brand.value?.offers
      ?.filter(offer => offer && offer.sort)
      .reduce<MappedOffers>((accu, offer) => {
        return {
          ...accu,
          [(offer?.sort || 0).toString()]: offer,
        };
      }, {});
  });

  return {
    brand,
    isFetching,
    mappedOffers,
  };
}

/**
 * useMegaMenuBrands used for displaying brands for mega menu
 * {params} limit
 */

export function useMegaMenuBrands(limit = 8) {
  const megaMenuBrands = ref<MegaMenuBrandsQuery['brands']['items']>([]);

  const { execute, isFetching } = useQuery({
    query: MegaMenuBrandsDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
    variables: {
      limit,
    },
  });

  useAsync(async () => {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    megaMenuBrands.value = data?.brands.items || [];
  });

  return {
    isFetching,
    megaMenuBrands,
  };
}

/**
 * useFeaturedBrands used for displaying brands for home page
 * writing it down in a seperate api in order to prevent hydrating the whole brands object to the home page
 * {params} limit
 */
export function useFeaturedBrandsSlider(limit = 12) {
  const { cacheParam } = useSetLocaleToCacheParams();
  const { corporateCacheParam } = useSetCorporateSourceToCacheParams();
  const cachingKey = pipe(cacheParam, corporateCacheParam)('brands');

  const cachedBrands = useCachedSsrRef<BrandsQuery['brands']['items']>(cachingKey, []);

  const { execute } = useQuery({
    query: FeaturedBrandsProductSliderDocument,
    fetchOnMount: false,
    variables: {
      limit,
    },
  });

  async function brands() {
    if (cachedBrands.value?.length) {
      return cachedBrands.value.filter(brands => brands?.is_shown_in_homepage);
    }
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    return data?.brands.items || [];
  }
  return {
    brands,
  };
}
