import { render, staticRenderFns } from "./HomeButton.vue?vue&type=template&id=4b1120df&"
import script from "./HomeButton.vue?vue&type=script&lang=ts&"
export * from "./HomeButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./HomeButton.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/AppLink.js').default,PredictAndWinResultModal: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/PredictAndWin/ResultModal.vue').default})
