













import { defineComponent } from '@nuxtjs/composition-api';
import { useAuth } from '~/features/auth';
export default defineComponent({
  setup() {
    const { isLoggedIn } = useAuth();

    return {
      isLoggedIn,
    };
  },
});
