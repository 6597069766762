import { render, staticRenderFns } from "./RayaPlusPointsBasicBanner.vue?vue&type=template&id=678a9e67&scoped=true&"
import script from "./RayaPlusPointsBasicBanner.vue?vue&type=script&lang=ts&"
export * from "./RayaPlusPointsBasicBanner.vue?vue&type=script&lang=ts&"
import style0 from "./RayaPlusPointsBasicBanner.vue?vue&type=style&index=0&id=678a9e67&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "678a9e67",
  null
  
)

/* custom blocks */
import block0 from "./RayaPlusPointsBasicBanner.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/AppLink.js').default})
