



























































import { defineComponent } from '@nuxtjs/composition-api';
import { useCorporate } from '~/features/corporates';

export default defineComponent({
  setup() {
    const { corporates } = useCorporate();

    return {
      corporates,
    };
  },
});
