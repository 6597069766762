import * as Types from '../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ContestsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ContestsQuery = { __typename?: 'Query', contests: Array<{ __typename?: 'Contest', description?: string | null | undefined, id: number, image_url?: string | null | undefined, mobile_image_url?: string | null | undefined, name?: string | null | undefined, responsive_image_url?: string | null | undefined, title?: string | null | undefined, today_matches: Array<{ __typename?: 'Match', description?: string | null | undefined, id: string, name: string, teamA?: string | null | undefined, teamA_image_url?: string | null | undefined, teamB?: string | null | undefined, teamB_image_url?: string | null | undefined, title?: string | null | undefined, match_time?: string | null | undefined, prediction_start_time?: string | null | undefined, prediction_end_time?: string | null | undefined, can_submit_prediction?: boolean | null | undefined, user_submission?: string | null | undefined } | null | undefined>, stages: Array<{ __typename?: 'Stage', id: string, description?: string | null | undefined, name?: string | null | undefined, title?: string | null | undefined, matches: Array<{ __typename?: 'Match', description?: string | null | undefined, id: string, name: string, teamA?: string | null | undefined, teamA_image_url?: string | null | undefined, teamB?: string | null | undefined, teamB_image_url?: string | null | undefined, title?: string | null | undefined, match_time?: string | null | undefined, prediction_start_time?: string | null | undefined, prediction_end_time?: string | null | undefined, can_submit_prediction?: boolean | null | undefined, user_submission?: string | null | undefined, match_result?: string | null | undefined, team_A_score?: string | null | undefined, team_B_score?: string | null | undefined, coupon_code?: string | null | undefined, user_win?: boolean | null | undefined } | null | undefined> } | null | undefined> } | null | undefined> };

export type MatchResultsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MatchResultsQuery = { __typename?: 'Query', contests: Array<{ __typename?: 'Contest', user_won_matches: Array<{ __typename?: 'Match', description?: string | null | undefined, id: string, name: string, teamA?: string | null | undefined, teamA_image_url?: string | null | undefined, teamB?: string | null | undefined, teamB_image_url?: string | null | undefined, title?: string | null | undefined, match_time?: string | null | undefined, prediction_start_time?: string | null | undefined, prediction_end_time?: string | null | undefined, can_submit_prediction?: boolean | null | undefined, user_submission?: string | null | undefined, match_result?: string | null | undefined, team_A_score?: string | null | undefined, team_B_score?: string | null | undefined, coupon_code?: string | null | undefined, user_win?: boolean | null | undefined } | null | undefined> } | null | undefined> };

export type PredictMatchMutationVariables = Types.Exact<{
  match_id: Types.Scalars['String'];
  match_prediction: Types.Scalars['String'];
}>;


export type PredictMatchMutation = { __typename?: 'Mutation', predictMatch: { __typename?: 'PredictMatchOutput', message: string, status: boolean } };

export type DismissPopupMutationVariables = Types.Exact<{
  match_id: Types.Scalars['String'];
}>;


export type DismissPopupMutation = { __typename?: 'Mutation', dismissMatchPredictionPopup: boolean };

export type ContestMatchFragment = { __typename?: 'Match', description?: string | null | undefined, id: string, name: string, teamA?: string | null | undefined, teamA_image_url?: string | null | undefined, teamB?: string | null | undefined, teamB_image_url?: string | null | undefined, title?: string | null | undefined, match_time?: string | null | undefined, prediction_start_time?: string | null | undefined, prediction_end_time?: string | null | undefined, can_submit_prediction?: boolean | null | undefined, user_submission?: string | null | undefined, match_result?: string | null | undefined, team_A_score?: string | null | undefined, team_B_score?: string | null | undefined, coupon_code?: string | null | undefined, user_win?: boolean | null | undefined };

export type ContestStageFragment = { __typename?: 'Stage', id: string, description?: string | null | undefined, name?: string | null | undefined, title?: string | null | undefined, matches: Array<{ __typename?: 'Match', description?: string | null | undefined, id: string, name: string, teamA?: string | null | undefined, teamA_image_url?: string | null | undefined, teamB?: string | null | undefined, teamB_image_url?: string | null | undefined, title?: string | null | undefined, match_time?: string | null | undefined, prediction_start_time?: string | null | undefined, prediction_end_time?: string | null | undefined, can_submit_prediction?: boolean | null | undefined, user_submission?: string | null | undefined, match_result?: string | null | undefined, team_A_score?: string | null | undefined, team_B_score?: string | null | undefined, coupon_code?: string | null | undefined, user_win?: boolean | null | undefined } | null | undefined> };

export declare const Contests: import("graphql").DocumentNode;
export declare const MatchResults: import("graphql").DocumentNode;
export declare const PredictMatch: import("graphql").DocumentNode;
export declare const DismissPopup: import("graphql").DocumentNode;
export declare const ContestMatch: import("graphql").DocumentNode;
export declare const ContestStage: import("graphql").DocumentNode;
export const ContestMatchFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ContestMatch"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Match"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"teamA"}},{"kind":"Field","name":{"kind":"Name","value":"teamA_image_url"}},{"kind":"Field","name":{"kind":"Name","value":"teamB"}},{"kind":"Field","name":{"kind":"Name","value":"teamB_image_url"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"match_time"}},{"kind":"Field","name":{"kind":"Name","value":"prediction_start_time"}},{"kind":"Field","name":{"kind":"Name","value":"prediction_end_time"}},{"kind":"Field","name":{"kind":"Name","value":"can_submit_prediction"}},{"kind":"Field","name":{"kind":"Name","value":"user_submission"}},{"kind":"Field","name":{"kind":"Name","value":"match_result"}},{"kind":"Field","name":{"kind":"Name","value":"team_A_score"}},{"kind":"Field","name":{"kind":"Name","value":"team_B_score"}},{"kind":"Field","name":{"kind":"Name","value":"coupon_code"}},{"kind":"Field","name":{"kind":"Name","value":"user_win"}}]}}]} as unknown as DocumentNode<ContestMatchFragment, unknown>;
export const ContestStageFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ContestStage"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Stage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"matches"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContestMatch"}}]}}]}},...ContestMatchFragmentDoc.definitions]} as unknown as DocumentNode<ContestStageFragment, unknown>;
export const ContestsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Contests"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"contests"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"image_url"}},{"kind":"Field","name":{"kind":"Name","value":"mobile_image_url"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"responsive_image_url"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"today_matches"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"teamA"}},{"kind":"Field","name":{"kind":"Name","value":"teamA_image_url"}},{"kind":"Field","name":{"kind":"Name","value":"teamB"}},{"kind":"Field","name":{"kind":"Name","value":"teamB_image_url"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"match_time"}},{"kind":"Field","name":{"kind":"Name","value":"prediction_start_time"}},{"kind":"Field","name":{"kind":"Name","value":"prediction_end_time"}},{"kind":"Field","name":{"kind":"Name","value":"can_submit_prediction"}},{"kind":"Field","name":{"kind":"Name","value":"user_submission"}}]}},{"kind":"Field","name":{"kind":"Name","value":"stages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContestStage"}}]}}]}}]}},...ContestStageFragmentDoc.definitions]} as unknown as DocumentNode<ContestsQuery, ContestsQueryVariables>;
export const MatchResultsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MatchResults"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"contests"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user_won_matches"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContestMatch"}}]}}]}}]}},...ContestMatchFragmentDoc.definitions]} as unknown as DocumentNode<MatchResultsQuery, MatchResultsQueryVariables>;
export const PredictMatchDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PredictMatch"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"match_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"match_prediction"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"predictMatch"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"match_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"match_id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"match_prediction"},"value":{"kind":"Variable","name":{"kind":"Name","value":"match_prediction"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<PredictMatchMutation, PredictMatchMutationVariables>;
export const DismissPopupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DismissPopup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"match_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dismissMatchPredictionPopup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"match_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"match_id"}}}]}}]}]}}]} as unknown as DocumentNode<DismissPopupMutation, DismissPopupMutationVariables>;