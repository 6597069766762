






import { ref, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';

export default {
  name: 'LazyLoader',
  setup() {
    const intersectionTarget = ref<Element | null>(null);
    const isVisible = ref(false);

    let intersectionObserver: IntersectionObserver | null = null;
    if (typeof IntersectionObserver !== 'undefined') {
      intersectionObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              isVisible.value = true;
              intersectionObserver?.unobserve(entry.target);
            }
          });
        },
        {
          // Detect when the target is 200px away from the viewport
          rootMargin: '0px 0px 200px 0px',
        }
      );
    }

    onMounted(() => {
      if (intersectionObserver && intersectionTarget.value) {
        intersectionObserver?.observe(intersectionTarget.value);
      }
    });

    onBeforeUnmount(() => {
      if (intersectionObserver && intersectionTarget.value) {
        intersectionObserver?.disconnect();
      }
    });

    return {
      intersectionTarget,
      isVisible,
    };
  },
};
