import * as Types from '../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CorporatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CorporatesQuery = { __typename?: 'Query', corporates: Array<{ __typename?: 'Corporate', id: number, image_url?: string | null | undefined, name?: string | null | undefined, require_employee_id?: boolean | null | undefined, require_hr_letter?: boolean | null | undefined, require_salary?: boolean | null | undefined } | null | undefined> };

export declare const Corporates: import("graphql").DocumentNode;

export const CorporatesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Corporates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"corporates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"image_url"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"require_employee_id"}},{"kind":"Field","name":{"kind":"Name","value":"require_hr_letter"}},{"kind":"Field","name":{"kind":"Name","value":"require_salary"}}]}}]}}]} as unknown as DocumentNode<CorporatesQuery, CorporatesQueryVariables>;