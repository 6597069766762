import { render, staticRenderFns } from "./ProductSlider.vue?vue&type=template&id=27776c42&"
import script from "./ProductSlider.vue?vue&type=script&lang=ts&"
export * from "./ProductSlider.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingProductCard: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/Loading/ProductCard.vue').default})
