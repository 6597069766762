






































import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { copyToClipboard } from '@/utils/text';
import { useAlerts } from '~/features/alerts';
import { Match, useDismissPopup, useFetchMatchResults } from '~/features/predictAndWin';
import { useI18n } from '~/features/i18n';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { error, success } = useAlerts();
    const { isFetching, matchResults } = useFetchMatchResults();
    const { dismissPopup } = useDismissPopup();

    const isShown = ref(false);
    const currentMatchIndex = ref(0);
    const match = ref<Match | {}>({});

    const unwatch = watch(matchResults, newValue => {
      if (newValue.length) {
        unwatch();
        displayDialogs();
      }
    });

    function displayDialogs() {
      if (currentMatchIndex.value > matchResults.value.length - 1) {
        return;
      }
      match.value = matchResults.value[currentMatchIndex.value];
      currentMatchIndex.value++;
      isShown.value = true;
    }

    watch(isShown, async newValue => {
      if (!newValue) {
        setTimeout(() => displayDialogs(), 1000);

        try {
          await dismissPopup({ match_id: (match.value as Match).id });
        } catch (e) {
          error(t('error') as string, t('wrong').toString());
        }
      }
    });

    function copyCode(value: string) {
      copyToClipboard(value);
      success('Code Copied');
    }
    return { isShown, copyCode, isFetching, matchResults, match };
  },
});
