import { render, staticRenderFns } from "./FeaturedCategory.vue?vue&type=template&id=e4e46fdc&scoped=true&"
import script from "./FeaturedCategory.vue?vue&type=script&lang=js&"
export * from "./FeaturedCategory.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedCategory.vue?vue&type=style&index=0&id=e4e46fdc&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4e46fdc",
  null
  
)

export default component.exports