import { computed } from '@nuxtjs/composition-api';
import { useMutation, useQuery } from 'villus';
import { GetUserContestPrizeDocument, SubmitAnswerDocument, TriviaContestsDocument } from '~/graphql/TriviaGame';

export function getTriviaContests() {
  const { data, execute, isFetching } = useQuery({
    query: TriviaContestsDocument,
    cachePolicy: 'network-only',
  });

  return {
    triviaContests: computed(() => data.value?.triviaContests[0]),
    isFetching,
    refetch: execute,
  };
}

export function useSubmitAnswer() {
  const { execute, isFetching } = useMutation(SubmitAnswerDocument);

  async function submitAnswer(choiceId: string) {
    const { data, error } = await execute({
      choiceId,
    });

    if (error) {
      throw new Error(error.message);
    }

    return {
      correctChoiceId: data?.submitAnswer?.correct_choice_id,
    };
  }

  return {
    submitAnswer,
    isFetching,
  };
}

export function useGetUserContestPrize() {
  const { execute, isFetching } = useQuery({
    query: GetUserContestPrizeDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  async function getUserContestPrize(contestId: number) {
    const { data, error } = await execute({
      variables: {
        contestId,
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    return {
      prize: data?.getUserContestPrize,
    };
  }

  return {
    getUserContestPrize,
    isFetching,
  };
}
