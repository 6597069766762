import { useQuery } from 'villus';
import { computed, useFetch } from '@nuxtjs/composition-api';
import { useSetLocaleToCacheParams } from './i18n';
import { useCachedSsrRef } from './serverCache';
import { CorporatesDocument, CorporatesQuery } from '~/graphql/Corporates';

export function useCorporate() {
  const { cacheParam } = useSetLocaleToCacheParams();

  const corporates = useCachedSsrRef<CorporatesQuery['corporates']>(cacheParam('corporates'), []);

  const { execute, isFetching } = useQuery({
    query: CorporatesDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  useFetch(async () => {
    // Don't refetch corporates unless cache expired
    if (corporates.value.length) {
      return;
    }

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    corporates.value = data?.corporates || [];
  });

  const featuredCorporateslider = computed(() =>
    corporates.value.sort((a, b) => sortStrings(a?.name ?? '', b?.name ?? ''))
  );

  return {
    corporates,
    isFetching,
    featuredCorporateslider,
  };
}

// sort strings by value , strings can be mixed of english and arabic words
export function sortStrings(a: string, b: string) {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  return collator.compare(a, b);
}
