import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=4987cea2&scoped=true&"
import script from "./ProductCard.vue?vue&type=script&lang=ts&"
export * from "./ProductCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=4987cea2&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4987cea2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductRating: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/ProductRating.vue').default})
