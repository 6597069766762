//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FeaturedCategory',
  props: {
    category: {
      type: Object,
      required: true,
    },
    silent: {
      type: Boolean,
      default: false,
    },
    negative: {
      type: Boolean,
      default: false,
    },
  },
  setup({ category }, { emit }) {
    function handleClick() {
      emit('click', category);
    }
    return { handleClick };
  },
};
