import { useMutation, useQuery } from 'villus';
import { format, isBefore } from 'date-fns';
import { ref, watch } from '@nuxtjs/composition-api';

import {
  ContestMatchFragment,
  ContestStageFragment,
  ContestsDocument,
  ContestsQuery,
  DismissPopupDocument,
  DismissPopupMutationVariables,
  MatchResultsDocument,
  PredictMatchDocument,
  PredictMatchMutationVariables,
} from '~/graphql/PredictAndWin';

export type Match = {
  id: string;
  teamA: {
    name: string;
    flag: string;
  };
  teamB: {
    name: string;
    flag: string;
  };
  date: string;
  predictionOpen?: boolean;
  lastSubmission?: string;
  title?: string;
  teamAScore?: string;
  teamBScore?: string;
  userWin?: boolean;
  couponCode?: string;
};

type MatchByStage = {
  date: string;
  matches: Match[];
};

type Stage = {
  id: string;
  title: string;
  days: MatchByStage[];
};

type ContestDetails = {
  title: string;
  description: string;
  bannerImgUrl: string;
  bannerImgResponsive: string;
};

export function useFetchContests() {
  const contests = ref<ContestsQuery['contests']>([]);
  const contestDetails = ref<ContestDetails | {}>({});
  const todayMatches = ref<Match[] | []>([]);
  const stages = ref<Stage[] | []>([]);

  const { data, isFetching } = useQuery({
    query: ContestsDocument,
    cachePolicy: 'network-only',
  });

  watch(data, value => {
    if (value) {
      contests.value = value.contests;

      contestDetails.value = {
        title: contests.value[0]?.title || '',
        description: contests.value[0]?.description || '',
        bannerImgUrl: contests.value[0]?.image_url || '',
        bannerImgResponsive: contests.value[0]?.responsive_image_url || '',
      };

      todayMatches.value =
        contests.value[0]?.today_matches
          .map(match => mapTodayMatches(match as ContestMatchFragment))
          .sort((a, b) => (isBefore(new Date((a as Match).date), new Date((b as Match).date)) ? -1 : 1)) || [];
    }

    stages.value =
      contests.value[0]?.stages.map(stage => ({
        id: stage?.id || '',
        title: stage?.title || '',
        days: mapGroupMatchesByDate(stage as any),
      })) || [];
  });

  return {
    isFetching,
    contestDetails,
    todayMatches,
    stages,
  };
}

export function useMakePrediction() {
  const { execute, isFetching: isMakingPrediction } = useMutation(PredictMatchDocument);

  async function submitPrediction(input: PredictMatchMutationVariables) {
    const { data, error } = await execute(input);

    if (
      error &&
      new RegExp('\\[GraphQL\\] User Can not Predict the match').test('[GraphQL] User Can not Predict the match')
    ) {
      throw new Error('canNotMakePrediction');
    }

    if (error) {
      throw new Error(error.message);
    }

    return data.predictMatch;
  }

  return {
    submitPrediction,
    isMakingPrediction,
  };
}

export function useFetchMatchResults() {
  const matchResults = ref();
  const { data, isFetching } = useQuery({
    query: MatchResultsDocument,
    cachePolicy: 'network-only',
  });

  watch(data, value => {
    if (value) {
      matchResults.value =
        value?.contests[0]?.user_won_matches.map(match => mapMatchResults(match as ContestMatchFragment)) || [];
    }
  });

  return {
    matchResults,
    isFetching,
  };
}

export function useDismissPopup() {
  const { execute, isFetching } = useMutation(DismissPopupDocument);

  async function dismissPopup(input: DismissPopupMutationVariables) {
    const { error } = await execute(input);

    if (error) {
      throw new Error(error.message);
    }
  }

  return {
    dismissPopup,
    isFetching,
  };
}

function mapTodayMatches(apiItem: ContestMatchFragment): Match {
  return {
    id: apiItem.id,
    title: apiItem.title || "Today's Match",
    teamA: {
      name: apiItem.teamA || '',
      flag: apiItem.teamA_image_url || '',
    },
    teamB: {
      name: apiItem.teamB || '',
      flag: apiItem.teamB_image_url || '',
    },
    date: apiItem.prediction_end_time || '',
    predictionOpen: apiItem.can_submit_prediction || false,
    lastSubmission: apiItem.user_submission || undefined,
  };
}

function mapMatchResults(apiItem: ContestMatchFragment) {
  return {
    id: apiItem.id,
    teamAName: apiItem.teamA || '',
    teamAFlag: apiItem.teamA_image_url || '',
    teamBName: apiItem.teamB || '',
    teamBFlag: apiItem.teamB_image_url || '',
    date: apiItem.match_time || '',
    userWin: apiItem.user_win || undefined,
    teamAScore: apiItem.team_A_score || '',
    teamBScore: apiItem.team_B_score || '',
    couponCode: apiItem.coupon_code || '',
    description: apiItem.description || '',
  };
}

function mapGroupMatchesByDate(apiItem: ContestStageFragment) {
  /**
   * group matches in each group by the date
   */
  const groupedMatches = apiItem.matches.reduce((grouped, match) => {
    const fullDate = match?.match_time || new Date().toString();
    const date = format(new Date(fullDate), 'yyyy-MM-dd');

    if (!grouped[date]) {
      grouped[date] = [];
    }

    grouped[date].push(match as ContestMatchFragment);
    return grouped;
  }, {} as { [k: string]: ContestMatchFragment[] });

  const mappedGroupsByDays: MatchByStage[] = [];

  for (const date in groupedMatches) {
    const matchesList = groupedMatches[date];
    const stageDate = matchesList[0].match_time;
    mappedGroupsByDays.push({
      date: stageDate || '',
      matches: matchesList.map(match => ({
        id: match.id,
        teamA: {
          name: match.teamA || '',
          flag: match.teamA_image_url || '',
        },
        teamB: {
          name: match.teamB || '',
          flag: match.teamB_image_url || '',
        },
        date: match.match_time || '',
        teamAScore: match.team_A_score || '',
        teamBScore: match.team_B_score || '',
      })),
    });
  }

  return mappedGroupsByDays.sort((a, b) => (isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1));
}
