import { computed, ref, useFetch } from '@nuxtjs/composition-api';
import { useQuery } from 'villus';
import { useCachedSsrRef } from './serverCache';
import { useSetLocaleToCacheParams } from './i18n';
import { mapProductListing, ProductNodes } from './products';
import { useFeaturedCategorySlider, Category } from './categories';
import { useFeaturedBrandsSlider } from './brands';
import { useStoreConfig } from './storeConfig';
import { useGetEliteInfo, useSetCorporateSourceToCacheParams } from './elite';
import { HomeDocument, HomeQuery } from '~/graphql/Home';
import { Brand, Offer } from '~/graphql-types.gen';
import { pipe } from '~/utils/collections';
import { EliteStoreHomeDocument } from '~/graphql/EliteHome';

type ProductCategorySlider = (Partial<Category> & { items: ProductNodes }) | undefined;

type ProductBrandSlider = Array<Partial<Brand> & { items: ProductNodes }>;

type MappedHomeOffers = Record<string | number, Offer> | undefined;

type HomeData = {
  offers: HomeQuery['homeOffers'];

  productUpperCategorySlider: ProductCategorySlider;

  productLowerCategorySlider: ProductCategorySlider;

  productBrandSlider: ProductBrandSlider;

  topDeals: ProductNodes;

  bestSellers: ProductNodes;

  mappedHomeOffers: MappedHomeOffers;

  metaData: HomeQuery['metaData'] | undefined;

  featuredBrands: HomeQuery['featuredBrands']['items'];
};

const HOME_QUERY_PENDING = new Map<
  string,
  | {
      data: HomeData | 'loading' | undefined;
      timestamp: number;
    }
  | undefined
>();

function isHomeDataValid(item: { data: HomeData | 'loading' | undefined; timestamp: number } | undefined): boolean {
  return !!item?.data && item.data !== 'loading' && Date.now() - item.timestamp < 30 * 1000; // 30 seconds in milliseconds
}

const QUERY_THRESHOLD = 450;

type HomeQueryType = 'default' | 'elite';

// Home page Categories sliders now are limited to 2 sliders only
export function useHomePage(type: HomeQueryType = 'default') {
  const { cacheParam } = useSetLocaleToCacheParams();
  const { corporateCacheParam } = useSetCorporateSourceToCacheParams();
  const { attachedCorporate } = useGetEliteInfo();

  const cachingKey = pipe(cacheParam, corporateCacheParam)('index');

  const { categories } = useFeaturedCategorySlider();
  const { brands } = useFeaturedBrandsSlider();

  const index = useCachedSsrRef<HomeData | undefined>(cachingKey, undefined, /* ttl = 30 min  */ 60 * 1000 * 30);

  const homeOffers = ref<HomeQuery['homeOffers'] | []>(index.value?.offers || []);

  const productUpperCategorySlider = ref<ProductCategorySlider>(index.value?.productUpperCategorySlider);

  const productLowerCategorySlider = ref<ProductCategorySlider>(index.value?.productLowerCategorySlider);

  const productBrandSlider = ref<ProductBrandSlider>(index.value?.productBrandSlider || []);

  const topDeals = ref<ProductNodes>(index.value?.topDeals || []);
  const bestSellers = ref<ProductNodes>(index.value?.bestSellers || []);

  const mappedHomeOffers = ref<MappedHomeOffers>(index.value?.mappedHomeOffers || {});
  const featuredBrands = ref<HomeQuery['featuredBrands']['items']>(index.value?.featuredBrands || []);

  const metaData = ref<HomeQuery['metaData'] | undefined>(index.value?.metaData || undefined);

  const { promotedCategoryId } = useStoreConfig();

  const { execute: executeHome, isFetching: isFetchingHome } = useQuery({
    query: HomeDocument,
    fetchOnMount: false,
    variables: {
      corporateId: attachedCorporate?.value?.id,
    },
  });

  const { execute: executeEliteStore, isFetching: isFetchingElite } = useQuery({
    query: EliteStoreHomeDocument,
    fetchOnMount: false,
    variables: {
      corporateId: attachedCorporate?.value?.id,
      sellerIds: attachedCorporate?.value?.assignedSellerIds ?? [],
    },
  });

  useFetch(async () => {
    if (index.value) {
      return;
    }
    if (HOME_QUERY_PENDING.get(cachingKey)?.data === 'loading') {
      let count = 0;

      // Wait until another process fetches the Home query
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (!HOME_QUERY_PENDING.get(cachingKey)) {
            // eslint-disable-next-line no-console
            console.log(
              '[Home Caching]: Result is fetched , Now Setting the Home to the one got from another process , ..continue'
            );

            clearInterval(interval);
            resolve(true);
          }

          if (count > QUERY_THRESHOLD) {
            // eslint-disable-next-line no-console
            console.log(
              '[Home Caching]: Timeout : Unable to get Home query from another process , resetting Home query and refetch again '
            );

            resolve(true);
            HOME_QUERY_PENDING.set(cachingKey, undefined);
          } else {
            count++;
          }
        }, 100);
      });
    }

    // Don't refetch Home page unless cache expired
    if (isHomeDataValid(HOME_QUERY_PENDING.get(cachingKey))) {
      index.value = HOME_QUERY_PENDING.get(cachingKey)?.data as HomeData;

      homeOffers.value = index.value?.offers || [];
      topDeals.value = index.value?.topDeals || [];
      productUpperCategorySlider.value = index.value?.productUpperCategorySlider;
      productLowerCategorySlider.value = index.value?.productLowerCategorySlider;
      productBrandSlider.value = index.value?.productBrandSlider || [];
      mappedHomeOffers.value = index.value?.mappedHomeOffers || ([] as any);
      featuredBrands.value = index.value?.featuredBrands || [];
      bestSellers.value = index.value?.bestSellers || [];
      return;
    }

    HOME_QUERY_PENDING.set(cachingKey, { data: 'loading', timestamp: 0 });

    const featuredCategorySlider = await categories();

    const featuredBrandSlider = await brands();

    const variables = {
      brands: featuredBrandSlider.map(brand => brand?.id + '')[0],
      upperCategory: featuredCategorySlider?.map(category => category.uid + '')[0] || '',
      lowerCategory: featuredCategorySlider?.map(category => category.uid + '')[1] || '',
      limit: 30,
      promotedCategoryId: promotedCategoryId.value,
    };

    const { data, error } = (type === 'elite'
      ? await executeEliteStore({
          variables: {
            ...variables,
            corporateId: attachedCorporate?.value?.id,
            sellerIds: attachedCorporate?.value?.assignedSellerIds ?? [],
          },
        })
      : await executeHome({
          variables,
        })) as {
      data?: HomeQuery;
      error?: Error;
    };

    if (error) {
      throw new Error(error?.message);
    }

    // if items is less than 3, then we should not show the products
    const brandsProducts =
      Number(data?.productBrandsSlider?.items?.length || 0) < 3
        ? []
        : data?.productBrandsSlider?.items?.map(mapProductListing as any) || [];

    const upperCategoryProducts =
      Number(data?.productUpperCategorySlider?.items?.length || 0) < 3
        ? []
        : data?.productUpperCategorySlider?.items?.map(mapProductListing as any) || [];

    const lowerCategoryProducts =
      Number(data?.productLowerCategorySlider?.items?.length || 0) < 3
        ? []
        : data?.productLowerCategorySlider?.items?.map(mapProductListing as any) || [];

    const metaData = data?.metaData;
    index.value = {
      offers: data?.homeOffers || [],
      topDeals:
        Number(data?.topDeals?.items?.length || 0) < 3
          ? []
          : data?.topDeals?.items?.map(mapProductListing as any) || [],
      productUpperCategorySlider: {
        ...featuredCategorySlider[0],
        items: (upperCategoryProducts as ProductNodes) || [],
      },
      productLowerCategorySlider: {
        ...featuredCategorySlider[1],
        items: (lowerCategoryProducts as ProductNodes) || [],
      },
      productBrandSlider: featuredBrandSlider.map(brand => ({
        ...brand,
        items: (brandsProducts as ProductNodes)?.filter(product => product?.brand?.id === brand?.id),
      })),
      mappedHomeOffers: data?.homeOffers
        .filter(offer => offer && offer.sort)
        .reduce<Record<string | number, Offer> | {}>((accu, offer) => {
          return {
            ...accu,
            [(offer?.sort || 0).toString()]: offer,
          };
        }, {}),
      metaData: metaData || undefined,
      featuredBrands: data?.featuredBrands.items || [],
      bestSellers:
        Number(data?.bestSellers?.bestsellers?.items?.length || 0) < 3
          ? []
          : data?.bestSellers?.bestsellers?.items?.map(mapProductListing as any) || [],
    };

    HOME_QUERY_PENDING.set(cachingKey, { data: index.value, timestamp: Date.now() });
    homeOffers.value = index.value?.offers || [];
    topDeals.value = index.value?.topDeals || [];
    productUpperCategorySlider.value = index.value?.productUpperCategorySlider;
    productLowerCategorySlider.value = index.value?.productLowerCategorySlider;
    productBrandSlider.value = index.value?.productBrandSlider || [];
    mappedHomeOffers.value = index.value?.mappedHomeOffers || ([] as any);
    featuredBrands.value = index.value?.featuredBrands || [];
    bestSellers.value = index.value?.bestSellers || [];
  });

  const isFetching = computed(() => isFetchingHome || isFetchingElite);

  return {
    isFetching,
    homeOffers,
    topDeals,
    productBrandSlider,
    productUpperCategorySlider,
    productLowerCategorySlider,
    mappedHomeOffers,
    featuredBrands,
    bestSellers,
    metaData,
  };
}
